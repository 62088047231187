<template>
  <v-row>
    <v-col cols="12">
      <v-textarea
        v-if="$vuetify.breakpoint.mobile"
        dense
        rows="3"
        label="* Nome"
        :rules="[this.$validators.string.required]"
        v-model.trim="value.nome"
      />
      <v-text-field
        v-else
        label="* Nome"
        dense
        :rules="[this.$validators.string.required]"
        v-model.trim="value.nome"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        label="CPF"
        dense
        placeholder="000.000.000-00"
        :rules="[this.$validators.string.cpf,this.$validators.string.required]"
        v-mask="'###.###.###-##'"
        v-model.trim="value.cpf"
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        label="* Email"
        dense
        :rules="[
          this.$validators.string.required,
          this.$validators.string.email,
        ]"
        v-model.trim="value.email"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        label="* Endereço"
        dense
        :rules="[this.$validators.string.required]"
        v-model.trim="value.endereco"
      />
    </v-col>
     <v-col cols="6">
      <v-text-field
        label="Telefone"
        dense
        placeholder=""
        :rules="[]"
        v-mask="'(##) ####-####'"
        v-model.trim="value.telefone"
      />
    </v-col>
     <v-col cols="6">
      <v-text-field
        label="* Celular"
        dense
        placeholder=""
        :rules="[this.$validators.string.required]"
        v-mask="'(##) #####-####'"
        v-model.trim="value.celular"
      />
    </v-col>
     <v-col cols="12">
      <v-text-field
        label="Link Info"
        dense
        placeholder=""
        v-model.trim="value.linkInfo"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ProfessorForm",
  props: {
    value: {
      type: Object,
    },
  },
  computed: {

  }
};
</script>

<style scoped>
</style>